import React, { useEffect, useState } from "react";
import moment from "moment";
import { Close, Plus, Remove } from "../../components/svgIcons";
import { Spin, notification } from "antd";
import axios from "axios";
import {
  LoadingOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
} from "@ant-design/icons";
import { isEmpty, orderBy, get, sortBy, set, lt, update } from "lodash";
import ReactModal from "react-modal";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Dropzone from "react-dropzone";
import s from "./admin.module.css";
import CKEditor from "@ckeditor/ckeditor5-react";
import trashIcon from "../../images/ic-trash.svg";
// import imageCompression from "browser-image-compression";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { v4 as uuid } from "uuid";
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
// import ReadMoreAndLess from "react-read-more-less";

// import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document"
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);
const antIconOne = (
  <LoadingOutlined style={{ fontSize: 24, color: "#000" }} spin />
);
let cancelToken;
// const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;
const HOSTNAME = "https://lnt-webapp-uat-001-liontreeapi.azurewebsites.net";
const jwtToken = localStorage.getItem("Authorization");
export default function AdminComponent(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState("theme");
  const [introLongDescription, setIntroLongDescription] = useState("");
  const [introPhoto, setIntoPhoto] = useState("");
  const [stockPhoto, setStockPhoto] = useState("");
  const [introStockImagePreview, setStockPhotoPreview] = useState({});
  const [stockImageError, setStockImageError] = useState(false);
  const [introStockImageLoader, setIntroStockLoader] = useState(false);
  const [WeeklyUpdateImage, setWeeklyUpdateImage] = useState("");
  const [WeeklyUpdateImagePreview, setWeeklyUpdateImagePreview] = useState({});
  const [WeeklyUpdateImageError, setWeeklyUpdateImageError] = useState(false);
  const [WeeklyUpdateImageLoader, setWeeklyUpdateImageLoader] = useState(false);
  const [introductionDataOne, setIntroductionDataOne] = useState({});
  const [introductionData, setIntroductionData] = useState({
    Id: 0,
    ItemId: uuid(),
    // "ItemId": "00000000-0000-0000-0000-000000000000",
    // "ItemName":  (new Date(this.setDate(this.getDate() - this.getDay() + 5))),
    ItemName: moment(new Date()).format("MM-DD-YYYY"),
    Photo: "",
    ShortDescription: "",
    LongDescription: "",
    WeeklyUpdateImage: "",
    StockItem: "",
    KindredCast: "",
    EmailSubject: "LionTree: Top Themes of the Week",
    WeeklyUpdateImage:
      "https://ltuatwestus2storpublic.blob.core.windows.net/lt-uat-westus2-ltweekly-image/unnamed%20%281%29_20233962243875.png",
    // "Created": new Date(),
    Created: new Date(),
    ShowInApp: false,
    ShowInWebsite: false,
    ShowSubscriptionInEmail: true,
    ShowSignatureInEmail: true,
    ShowDownloadPDFInEmail: true,
    // OtherNewsButtonInEmail: false,
    EmailTemplate:"",
    Category: "",
    LTWeeklyItem: [],
    PdfLinkUrl: "",
  });
  const [IscalledPollAPI, setIsCalledPollApi] = useState(false);
  const [ltWeeklyItemData, setLtWeeklyItemData] = useState([]);
  const [agoloNewsData, setAgoloNewsData] = useState([]);
  const [selectedNews, setSelectedNews] = useState([]);
  const [loader, setLoader] = useState(true);
  const [curatedList, setCuratedList] = useState([]);
  const [allChecked, setAllChecked] = useState(true);
  const [selectedCuratedValue, setSelectedCuratedValue] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCalled, setIsCallApi] = useState(false);
  const [date, setDate] = useState(false);
  const [introImageLoader, setIntroImageLoader] = useState(false);
  const [introLoader, setIntroLoader] = useState(false);
  const [isLtWeeklyData, setIsLtWeeklyData] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [introImagePreview, setIntroImagePreview] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedRecord, setDeletedRecord] = useState(-1);
  const [introductionError, setIntroductionError] = useState("");
  const [imageUploadPendingCount, setImageUploadPendingCount] = useState(0);
  const state = location?.state;
  const [emailModal, setEmailModal] = useState(false);
  const [mailingListData, setMailingListData] = useState([]);
  const [selectedMailingList, setSelectedMailingList] = useState([]);
  const [userListId, setUserListId] = useState("");
  const [campaignTitle, setCampaignTitle] = useState("");
  const [campaignTitleError, setCampaignTitleError] = useState("");
  const [mailingListError,setMailingListError]=useState("")
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);
  const [errorList, setErrorList] = useState([]);
  let { ltWeeklyDate } = useParams();
  const [sort, setSort] = useState(true);
  const [deleteNewsModal, setDeleteNewsModal] = useState(false);
  const [deletedAgoloNews, setDeletedAgoloNews] = useState({});
  const [isDeleteData, setIsDeleteData] = useState(false);
  const [isStatusDisabled, setIsStatusDisabled] = useState(false);
  const [isAllStatusDisabled, setIsAllStatusDisabled] = useState(true);
  const [selectedAgoloNewsStatus, setSelectedAgoloNewsStatus] = useState("");
  const [photoError, setPhotoError] = useState("");
  const [appTitleError, setAppTitleError] = useState("");
  const [openWebsiteModal, setOpenWebsiteModal] = useState(false);
  const [openAppModal, setOpenAppModal] = useState(false);
  const [showFullDescription, setFullDescription] = useState(false);
  const [isSavedDataExist, setIsSavedDataExist] = useState(false);
  const [indexes, selectedIndex] = useState([]);
  const [isSyncLtWeeklyLoader, setIsSyncLtWeeklyLoader] = useState(false);
  const [oldStatus, setOldStatus] = useState("");
  const [generatePdfLoader, setGeneratePdfLoader] = useState(false);
  const [campaignType, setCampaignType] = useState("Test");
  const [groupDates, setGroupDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState("All");
  const [searchParams] = useSearchParams();
  const [themeLoader, setThemeLoader] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [pageSearchNumber, setPageSearchNumber] = useState(1);
  const [nextStep, setNextStep] = useState(0);
  const initialOptions = [
    { value: "", label: "Option 1" },
    { value: "", label: "Option 2" },
  ];
  const [options, setOptions] = useState(initialOptions);
  const [question, setQuestion] = useState("");
  const [pollSaveLoader, setPollSaveLoader] = useState(false);
  const [questionError, setQuestionError] = useState("");
  const [optionError, setOptionError] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [pollData, setPollData] = useState([]);
  const [totalPollResultCount, setTotalPollResultCount] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const [pollDataById, setPollDataById] = useState([]);
  const [pollResultData, setPollResultData] = useState([]);
  const [iscorrectAnswerExist, setIsCorrectAnswerExist] = useState(
    localStorage.getItem("iscorrectAnswerExist") === "true" ? true : false
  );
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [correctPollAnswer, setCorrectPollAnswer] = useState("");
  // const [OtherNewsOptionType,setOtherNewsOptionType]=useState(1)
  const [
    selectedAgoloNewsStatusWithComma,
    setSelectedAgoloNewsStatusWithComma,
  ] = useState([
    { value: "Pending", label: "Draft", isDisabled: isStatusDisabled },
    { value: "Completed", label: "Approved", isDisabled: isStatusDisabled },
    { value: "Saved", label: "Saved", isDisabled: isStatusDisabled },
  ]);
  const [isLive, setIsLive] = useState(false);
  const [IsSendButtonDisabled,setIsSendButtonDisabled]= useState(false)
  const articleStatus = location?.state?.articleStatus;

  const closeNewsModal = () => {
    setDeletedAgoloNews({});
    setDeleteNewsModal(false);
    setOpenWebsiteModal(false);
    setOpenAppModal(false);
  };
  const removeOtherNews = async () => {
    setIsLoading(true);
    let payload = {
      articleId: deletedAgoloNews?.Id,
    };
    const { value } = await props.removeAgoloNews({ Id: payload?.articleId });
    if (value.Success) {
      // const filteredNews = agoloNewsData.filter((item) => item.Id !== deletedAgoloNews?.Id);
      // setAgoloNewsData([...filteredNews])
      agoloNewsData.splice(
        agoloNewsData.findIndex((data) => data.Id === deletedAgoloNews.Id),
        1
      );
      // const filteredNews = await agoloNewsData.filter((item) => item.Id !== payload?.articleId);
      setAgoloNewsData([...agoloNewsData]);
      // getSavedArticles(ltWeeklyDate)
      // callApiWithStatus(ltWeeklyDate, selectedAgoloNewsStatus);

      closeNewsModal();
      setIsDeleteData(true);
      // updateLtweeklyData(true);
      openNotificationWithIcon("success", "Deleted News successfully.");
      setDeletedAgoloNews({});
      setIsLoading(false);
    } else {
      closeNewsModal();
      setDeletedAgoloNews({});
      setIsLoading(false);
    }
  };

  const handleCampaignType = (value) => {
    setUserListId("")
    setCampaignType(value);
    if(value === "Live"){
      setIsLive(true)
    }else{
      setIsLive(false)
    }
  };

  const StatusOptions = [
    { value: "All", label: "All Status", isDisabled: isAllStatusDisabled },
    { value: "Pending", label: "Draft", isDisabled: isStatusDisabled },
    { value: "Saved", label: "Saved", isDisabled: isStatusDisabled },
    { value: "Completed", label: "Approved", isDisabled: isStatusDisabled },
  ];
  const campaignTypeOptions = [
    { value: "Live", label: "Live Campaign" },
    { value: "Test", label: "Test Campaign" },
  ];
  const handlemailotherNewsOptionsType =(value) => {
    introductionData.EmailTemplate = value
    console.log(introductionData.EmailTemplate,"=======>?")
    setIntroductionData({ ...introductionData })
    // setOtherNewsOptionType(value)
  }

  const mailOtherNewsOptions=[
    {value:0, label:"No Other News in Email"},
    {value:1, label:"Other News Button in Email"},
    {value:2, label:"Other New List in Email"},
    {value:3, label:"Other News Categorizes in Email"},
  ]
  const closeEmailModal = () => {
    console.log("is here")
    setUserListId("")
    setCampaignType("Test")
    setCampaignTitleError("")
    setMailingListError("")
    setMailingListData([])
    setIsLive(false)
    setEmailModal(false);
  };

  const handleStatus = (status) => {
    let findAll = status?.findIndex((s) => s?.value !== "All");
    if (findAll !== -1) {
      setIsAllStatusDisabled(true);
    }
    if (status.length == 0) {
      setIsAllStatusDisabled(false);
      // setSelectedAgoloNewsStatus("All")
      setIsStatusDisabled(false);
    } else {
      setLoader(true);
      status.map((item) => {
        return item.value === "All"
          ? setIsStatusDisabled(!isStatusDisabled)
          : setIsStatusDisabled(false);
      });
    }
    const selectedStatus = status.map((item) => {
      return item.value;
    });
    let finalStatus = selectedStatus.join("|");
    const finalStatusWithComma = selectedStatus.join(",");
    setSelectedAgoloNewsStatusWithComma(finalStatusWithComma);
    // if(!finalStatus){
    //   finalStatus = "All"
    // }
    setSelectedAgoloNewsStatus(finalStatus);

    if (selectedDate && selectedDate !== "All") {
      if (!finalStatus) {
        finalStatus = "All";
      }
      getNewsBySelectedDate(selectedDate, finalStatus);
    } else {
      if (!finalStatus) {
        finalStatus = "All";
      }
      callApiWithStatus(ltWeeklyDate, finalStatus);
    }
  };

  const CallApiForGetMailingList = async () => {
  
    const { value } = await props.getMailingList({
      page: 1,
      count: 200,
    });
    if (value?.Result?.Data?.length > 0) {
      setIsLive(false)
      setMailingListData(value?.Result?.Data);
      // handleMailingList(value?.Result?.Data[0]);
      // handleMailingList();
    }
  };
  const handleMailingList = (list) => {
    setSelectedMailingList(list);
    setUserListId(list?.Id);
  };
  const handleCampaignTitle = (title) => {
    setCampaignTitleError("");
    setCampaignTitle(title);
  };
  const bytesToSize = (bytes) => bytes / (2048 * 2048);
  const callCuratedTagListAPI = async () => {
    const { value } = await props.getCuratedTags();
    if (value) {
      let list = [];
      let prm = value?.Result?.map((r) => {
        let v = {
          value: r.Id,
          label: r.Name,
          color: "#00B8D9",
          isFixed: false,
        };
        list.push(v);
      });
      await Promise.all(prm);
      setCuratedList([...list]);
    }
  };

  const addNewTheme = async () => {
    let data = ltWeeklyItemData;
    let ItemName = ltWeeklyItemData[ltWeeklyItemData.length - 1]?.ItemName;
    if (ItemName) {
      ItemName = parseInt(ItemName);
    } else {
      ItemName = 0;
    }
    // setDeletedRecord(ltWeeklyItemData.length - 1);
    data.push({
      Id: 0,
      LTweeklyId: introductionData?.ItemId,
      ItemId: uuid(),
      ItemName: (ItemName + 1).toString(),
      Title: "",
      Photo: "",
      ShortDescription: "",
      LongDescription: "",
      // "PrimaryTag": "",
      Created: new Date(),
      // Created: moment().format("Y-MM-DD HH:mm:ss.SSS Z"),
    });
    setLtWeeklyItemData([...data]);
    //   if(introductionData.Id!==0){
    //   let payload = {
    //     Data: introductionData,
    //     LtWeeklyItems: data,
    //   };
    //   const { value } = await props.updateLtweeklyDetails(payload);
    // }
  };

  const callApiWithStatus = async (d, status) => {
    //d = d?.replace("Week ending ", "")
    if (status || selectedAgoloNewsStatus) {
      // if(oldStatus === selectedAgoloNewsStatus){
      //   status = "All"
      // }

      let endDate = new Date(d);
      let startDate = new Date(d);
      var lastSaturday = new Date(
        new Date(d).setDate(
          new Date(d).getDate() -
            (new Date(d).getDay() == 0 ? 7 : new Date(d).getDay() + 1)
        )
      );
      endDate = moment(endDate, "MM DD YYYY").format("YYYY-MM-DD");
      // startDate = startDate.setDate(startDate.getDate() - 6);
      startDate = moment(lastSaturday, "MM DD YYYY").format("YYYY-MM-DD");
      let lastDate = new Date(lastSaturday.setDate(lastSaturday.getDate() + 6));

      endDate = moment(lastDate, "MM DD YYYY").format("YYYY-MM-DD");
      let data = {
        startDate: startDate + " 00:00:00",
        endDate: endDate + " 23:59:00",
        isThisWeek: true,
        status: !status ? selectedAgoloNewsStatus : status,
        token: "",
      };
      if (data.status === oldStatus && !selectedDate) {
        setLoader(true);
        data.status = "All";
      }
      setOldStatus(data.status);

      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      cancelToken = axios.CancelToken.source();
      data.token = cancelToken.token;
      const { value } = await props.getAgoloNewsByDate(data);
      if (value) {
        let news = orderBy(value?.Data, ["Id"], ["desc"]);
        setAgoloNewsData([...news]);
        let l = setInterval(() => {
          setLoader(false);
          clearInterval(l);
        }, 1000);
      }
    }
  };

  const callApi = async (d) => {
    //d = d?.replace("Week ending ", "");
    let endDate = new Date(d);

    let startDate = new Date(d);
    var lastSaturday = new Date(
      new Date(d).setDate(
        new Date(d).getDate() -
          (new Date(d).getDay() == 0 ? 7 : new Date(d).getDay() + 1)
      )
    );
    endDate = moment(endDate, "MM DD YYYY").format("YYYY-MM-DD");
    // startDate = startDate.setDate(startDate.getDate() - 6);
    startDate = moment(lastSaturday, "MM DD YYYY").format("YYYY-MM-DD");
    let lastDate = new Date(lastSaturday.setDate(lastSaturday.getDate() + 6));
    endDate = moment(lastDate, "MM DD YYYY").format("YYYY-MM-DD");
    // endDate = moment(ltWeeklyDate, "MMMM-DD-YYYY").format("YYYY-MM-DD");
    let data = {
      startDate: startDate + " 00:00:00",
      endDate: endDate + " 23:59:00",
      isThisWeek: true,
      // isThisWeek: currentClickDate === thisWeekSaturdayDate ? true : false,
      // isThisWeek: new Date(endDate) > new Date() ? true : false,
      status: "Completed|Pending|Saved",
      token: "",
    };

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    data.token = cancelToken.token;
    const { value } = await props.getAgoloNewsByDate(data);
    if (value) {
      let news = orderBy(value?.Data, ["Category"], ["asc"]);
      setAgoloNewsData([...news]);
      let l = setInterval(() => {
        setLoader(false);
        clearInterval(l);
      }, 1000);
    }
  };
  const getNewsBySelectedDate = async (selectedDate, status) => {
    try {
      let d = ltWeeklyDate;
      if (status == "") {
        status = "All";
      }
      if (selectedDate === "All") {
        callApiWithStatus(d, status);
      } else {
        const formattedStartDate = moment(selectedDate).format(
          "YYYY-MM-DD 00:00:00"
        );
        const formattedEndDate = moment(selectedDate).format(
          "YYYY-MM-DD 23:59:59"
        );
        const data = {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          isThisWeek: true,
          status: !status
            ? !selectedAgoloNewsStatus
              ? "All"
              : selectedAgoloNewsStatus
            : status,
          // status:  !selectedAgoloNewsStatus ? "All" : selectedAgoloNewsStatus,
          // status: !status ? selectedAgoloNewsStatus : status
        };
        cancelToken = axios.CancelToken.source();

        data.token = cancelToken.token;
        const { value } = await props.getAgoloNewsByDate(data);
        if (value) {
          let news = orderBy(value?.Data, ["Id"], ["desc"]);
          setAgoloNewsData([...news]);
          let l = setInterval(() => {
            setLoader(false);
            clearInterval(l);
          }, 1000);
        }
      }
    } catch (error) {
      console.error("Error fetching news by selected date:", error);
    }
  };
  const callApiAfterAddCurated = async (d) => {
    //d = d?.replace("Week ending ", "");
    let endDate = new Date(d);
    let startDate = new Date(d);
    endDate = moment(endDate, "MM DD YYYY").format("YYYY-MM-DD");
    startDate = startDate.setDate(startDate.getDate() - 5);
    startDate = moment(new Date(startDate), "MM DD YYYY").format(
      "YYYY-MM-DD HH:mm:ss"
    );
    let data = {
      startDate: startDate,
      endDate: endDate + " 23:59:00",
      token: "",
    };

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    data.token = cancelToken.token;
    const { value } = await props.getAgoloNewsByDate(data);
    if (value) {
      setAgoloNewsData([...value?.Data]);
      let l = setInterval(() => {
        setLoader(false);
        clearInterval(l);
      }, 1000);
    }
  };
  // Slect End
  const closeModal = () => {
    setDeleteModal(false);
    setDeletedRecord(-1);
    setSelectedCuratedValue([]);
    setIsOpen(false);
  };

  const removeTheme = async () => {
    let key = deletedRecord;
    let data = ltWeeklyItemData[key];
    ltWeeklyItemData.splice(key, 1);
    setLtWeeklyItemData([...ltWeeklyItemData]);
    const { value } = await props.removeThemeById({ Id: data?.ItemId });
    if (value?.Success) {
      // if(introductionData.Id!==0){
      // // callApiGetLtWeeklyItems(ltWeeklyDate);
      // }
    }
    if (deletedRecord > 0) {
      setDeletedRecord(deletedRecord - 1);
    }
    setDeleteModal(false);
  };
  // Select Start
  const animatedComponents = makeAnimated();
  const onCheck = async (item, key = "single") => {
    if (key === "single") {
      const found = selectedNews.findIndex((el) => el.Id === item.Id);
      if (found === -1) {
        setSelectedNews([...selectedNews, item]);
        if (selectedNews?.length + 1 === agoloNewsData.length) {
          setAllChecked(false);
        }
      } else {
        selectedNews.splice(found, 1);
        setAllChecked(true);
        setSelectedNews([...selectedNews]);
      }
    } else {
      const markData = [];
      if (!allChecked) {
        setAllChecked(true);
        setSelectedNews([]);
      } else {
        let agolo = agoloNewsData.map((item, index) => {
          markData.push(item);
        });
        await Promise.all(agolo);
        setSelectedNews(markData);
        setAllChecked(false);
      }
    }
  };

  const showFullDescriptionHandler = (i) => {
    let data = indexes;
    if (data.includes(i)) {
      data.pop(i);
    } else {
      data.push(i);
    }
    selectedIndex([...data]);
    setFullDescription(!showFullDescription);
  };

  const addCurated = async () => {
    setIsLoading(true);
    let payload = {
      Data: [],
    };
    let data = [];
    let prm = selectedNews?.map(async (s) => {
      let curated = s?.Curated?.split("|");
      if (!curated || curated[0] === "") {
        curated = [];
      }
      let l = selectedCuratedValue?.map((e) => {
        const found = curated.findIndex(
          (el) => el?.toString() === e.value?.toString()
        );
        if (found === -1) {
          curated.push(e.value?.toString());
        }
      });
      curated = curated?.join("|");
      data.push({ Id: s.Id, Curated: curated });
      await Promise.all(l);
    });
    await Promise.all(prm);
    payload.Data = data;
    const { value } = await props.addCuratedTags(payload);
    if (value?.Success) {
      setIsOpen(false);
      setIsLoading(false);
      setLoader(true);
      setDate(ltWeeklyDate);
      // callCuratedTagListAPI();
      callApiAfterAddCurated(ltWeeklyDate);
      setAllChecked(true);
      setSelectedCuratedValue([]);
      setSelectedNews([]);
    }
  };
  const handleDateSelection = async (selectedOption) => {
    const finalStatus = selectedAgoloNewsStatus;
    setSelectedDate(selectedOption.value); // Update selected date
    // await props.getAgoloNewsByDate(selectedOption.value); // Call your news API with the selected date
    getNewsBySelectedDate(selectedOption.value, finalStatus);
  };

  const fetchGroupDates = async () => {
    try {
      const date = ltWeeklyDate;
      const endDate = moment(date, "MMMM-DD-YYYY").format("YYYY-MM-DD");
      const startDate = moment(endDate, "YYYY-MM-DD")
        .subtract(6, "days")
        .format("YYYY-MM-DD");
      const formattedStartDate = startDate + " 00:00:00";
      const formattedEndDate = endDate + " 23:59:59";
      const value = {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      };

      const dates = await props.getAllCreatedAgoloNewsDate(value);
      const formattedDates =
        dates?.value?.Result?.map((item) => ({
          value: item.GroupDate,
          label: moment(item.GroupDate).format("MMM DD, YYYY"), // Format the date as you need
        })) || [];
      setGroupDates(formattedDates);
    } catch (error) {
      console.error("Error fetching group dates:", error);
    }
  };
  useEffect(() => {
    fetchGroupDates();
  }, []);

  useEffect(() => {}, [
    agoloNewsData,
    introductionData,
    curatedList,
    imageUploadPendingCount,
    selectedAgoloNewsStatus,
  ]);
  const callApiAddLTWeeklyAuto = async (d) => {
    let date = new Date(d);
    date = moment(date, "MMM-DD-YYYY").format("MM-DD-YYYY");

    // const {value}= await props.AddLTWeeklyAuto({ WeeklyDate: date });
    callApiGetLtWeeklyItems(ltWeeklyDate);
  };

  const changeTab = async (tab, isTrue = false) => {
    setTab(tab);
    if (tab === "theme") {
      navigate(`/themes/${ltWeeklyDate}`);
    } else if (tab === "other-news") {
      const locationState = location?.state || {};
      if (isTrue && searchParams.get("articleStatus")) {
        let s = "";
        if (searchParams.get("articleStatus") === "draft") {
          s = "Pending";
        } else if (searchParams.get("articleStatus") === "all") {
          s = "Pending|Completed|Saved";
        } else {
          s = searchParams.get("articleStatus");
        }
        callApiWithStatus(ltWeeklyDate, s);
      } else {
        callApiWithStatus(ltWeeklyDate, selectedAgoloNewsStatus);
      }
      const searchParam = searchParams.get("articleStatus");
      if (searchParam) locationState.articleStatus = searchParam;
      navigate(`/other-news/${ltWeeklyDate}`, {
        state: locationState,
      });
    } else if (tab === "poll") {
      navigate(`/poll/${ltWeeklyDate}`);
    } else if (tab === "poll results") {
      navigate(`/poll-results/${ltWeeklyDate}`);
    }
  };

  const callApiGetLtWeeklyItems = async (date) => {
    //date = date?.replace("Week ending ", "");
    setLtWeeklyItemData([]);
    date = new Date(date);
    date = moment(date, "MM DD YYYY").format("MM-DD-YYYY");
    const { value } = await props.getLtWeeklyItems({ date });
    if (value?.Success) {
      if (!isEmpty(value?.Result?.Data)) {
        setIntroductionData({ ...value?.Result?.Data });
        setIntroductionDataOne({ ...value?.Result?.Data });
        setIntoPhoto(value?.Result?.Data?.Photo);
        setStockPhoto(value?.Result?.Data?.StockItem);
        setWeeklyUpdateImage(value?.Result?.Data?.WeeklyUpdateImage);
        setIntroLongDescription(value?.Result?.Data?.LongDescription);
      } else {
        setWeeklyUpdateImage(
          "https://ltuatwestus2storpublic.blob.core.windows.net/lt-uat-westus2-ltweekly-image/unnamed%20%281%29_20233962243875.png"
        );
      }

      if (value?.Result?.LtWeeklyItems?.length > 0) {
        setLtWeeklyItemData([...value?.Result?.LtWeeklyItems]);
      }
      let clearTime = setInterval(() => {
        setIsLtWeeklyData(true);
        clearInterval(clearTime);
      }, 1000);
      setLoader(false);
    }
  };

  const getSavedArticles = async (d) => {
    let endDate = new Date(d);

    let startDate = new Date(d);
    var lastSaturday = new Date(
      new Date(d).setDate(
        new Date(d).getDate() -
          (new Date(d).getDay() == 0 ? 7 : new Date(d).getDay() + 1)
      )
    );
    endDate = moment(endDate, "MM DD YYYY").format("YYYY-MM-DD");
    // startDate = startDate.setDate(startDate.getDate() - 6);
    startDate = moment(lastSaturday, "MM DD YYYY").format("YYYY-MM-DD");
    let lastDate = new Date(lastSaturday.setDate(lastSaturday.getDate() + 6));

    endDate = moment(lastDate, "MM DD YYYY").format("YYYY-MM-DD");
    let data = {
      startDate: startDate + " 00:00:00",
      endDate: endDate + " 23:59:00",
      isThisWeek: true,
      // isThisWeek: introductionData?.LongDescription !== '' ? false : true,
      // isThisWeek: currentClickDate === thisWeekSaturdayDate ? true : false,
      status: "Saved",
      token: "",
    };

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    // data.token = cancelToken.token;
    const { value } = await props.getAgoloNewsByDate(data);
    if (value.Data?.length > 0) {
      setIsSavedDataExist(true);
    } else {
      setIsSavedDataExist(false);
    }
  };

  useEffect(() => {
    if (!isCalled) {
      let status = "";

      if (
        (!articleStatus || articleStatus === "all") &&
        !searchParams.get("articleStatus")
      ) {
        status = "Completed|Pending|Saved";
        handleStatus([StatusOptions[1], StatusOptions[3], StatusOptions[2]]);
      } else if (articleStatus === "completed") {
        status = "Completed";
        handleStatus([StatusOptions[3]]);
      } else if (articleStatus === "draft") {
        status = "Pending";
        handleStatus([StatusOptions[1]]);
      }
      if (articleStatus === "saved") {
        status = "Saved";
        handleStatus([StatusOptions[2]]);
      }
      setSelectedAgoloNewsStatus(status);
      setDate(ltWeeklyDate);
      callCuratedTagListAPI();
      if (searchParams.get("articleStatus")) {
        let s = "";
        if (searchParams.get("articleStatus") === "draft") {
          s = "Pending";
        } else if (searchParams.get("articleStatus") === "all") {
          s = "Pending|Completed|Saved";
        } else {
          s = searchParams.get("articleStatus");
        }
        // callApiWithStatus(ltWeeklyDate, s);
      } else {
        // callApiWithStatus(ltWeeklyDate, status);
      }
      // callApi(ltWeeklyDate);
      setIsCallApi(true);
      callApiAddLTWeeklyAuto(ltWeeklyDate);
      getSavedArticles(ltWeeklyDate);
      getModifyCuratedList();
      if (location.pathname?.includes("other-news")) {
        changeTab("other-news", true);
      } else if (location.pathname?.includes("poll-results")) {
        changeTab("poll results");
      } else if (location.pathname?.includes("poll")) {
        changeTab("poll");
      }

      CallApiForGetMailingList();
    }
  }, [ltWeeklyItemData, introductionData]);

  const custom_config = {
    extraPlugins: [MyCustomUploadAdapterPlugin],
    toolbar: {
      htmlEmbed: true,
      shouldNotGroupWhenFull: true,
      mediaEmbed: false,
      table: false,
      imageResize: false,
    },
    image: {
      styles: {
        options: [],
      },
    },
    removePlugins: ["MediaEmbed", "Table", "ImageInsert"],
  };
  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new MyUploadAdapter(loader, this.props);
    };
  }
  class MyUploadAdapter {
    constructor(props) {
      // CKEditor 5's FileLoader instance.
      this.loader = props;
      // URL where to send files.
      this.url = `${HOSTNAME}/Dashboard/AddImage`;
    }
    // Starts the upload process.
    upload() {
      return new Promise((resolve, reject) => {
        this._initRequest();
        this._initListeners(resolve, reject);
        this._sendRequest();
      });
    }
    // Aborts the upload process.
    abort() {
      if (this.xhr) {
        this.xhr.abort();
      }
    }
    // Example implementation using XMLHttpRequest.
    _initRequest() {
      const xhr = (this.xhr = new XMLHttpRequest());
      xhr.open("PUT", this.url, true);
      xhr.responseType = "json";
      let token = "";
      if (!jwtToken) {
        token = localStorage.getItem("Authorization");
      } else {
        token = jwtToken;
      }
      let auth = "Bearer " + token;
      xhr.setRequestHeader("Content-type", "application/json");
      //xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
      xhr.setRequestHeader("Authorization", auth);
    }
    // Initializes XMLHttpRequest listeners.
    _initListeners(resolve, reject) {
      const xhr = this.xhr;
      const loader = this.loader;
      const genericErrorText =
        "Couldn't upload file:" + ` ${loader.file.name}.`;
      xhr.addEventListener("error", () => reject(genericErrorText));
      xhr.addEventListener("abort", () => reject());
      xhr.addEventListener("load", () => {
        const response = xhr.response;
        if (!response || response.error) {
          return reject(
            response && response.error
              ? response.error.message
              : genericErrorText
          );
        }
        let count = imageUploadPendingCount - 1;
        if (count > 0) {
          setImageUploadPendingCount(count);
        } else {
          setImageUploadPendingCount(0);
        }
        // setLtWeeklyItemData([...ltWeeklyItemData])
        resolve({
          default: response,
        });
      });
      if (xhr.upload) {
        xhr.upload.addEventListener("progress", (evt) => {
          if (evt.lengthComputable) {
            loader.uploadTotal = evt.total;
            loader.uploaded = evt.loaded;
          }
        });
      }
    }
    _sendRequest() {
      this.loader.file.then((result) => {
        let reader = new FileReader();
        var self = this;
        reader.onloadend = async () => {
          let baseVal = reader.result
            ?.replaceAll("data:image/png;base64,", "")
            .replaceAll("data:image/jpeg;base64,", "");
          let ext = result.type.split("/")[1];
          let fileName = result.name?.replaceAll(`.${ext}`, "");
          let pay = { Content: baseVal, Extention: ext, FileName: fileName };
          self.xhr.send(JSON.stringify(pay)); // Make sure to stringify
          let count = imageUploadPendingCount + 1;
          if (count > 0) {
            setImageUploadPendingCount(count);
          } else {
            setImageUploadPendingCount(0);
          }
          // data.append('Content',baseVal)
          // data.append('Extention',ext)
          // self.xhr.send(data)
        };
        reader.readAsDataURL(result);
        //   data.append("file", result)
      });
    }
  }

  const addBrTag = (a) => {
    if (!a?.includes("<strong>")) {
      let b = a.slice(0, a.indexOf(". "));
      let c = a.slice(a.indexOf(". ") + 1, a.length);
      let contentString = "<p><b>" + b + ". " + "</b>" + c + "</p>";
      return contentString.replaceAll("<p>", "").replaceAll("</p>", "");
    } else {
      return a;
    }
  };

  const removeIntroPhoto = () => {
    introductionData["Photo"] = "";
    setLtWeeklyItemData([...ltWeeklyItemData]);
    setIntoPhoto("");
    setIntroImagePreview({});
  };

  const addCuratedList = async (e, data) => {
    let curated = [];
    let l = e?.map((e) => {
      curated.push(e.value?.toString());
    });
    await Promise.all(l);
    curated = curated.join("|");
    let payload = {
      Data: [
        {
          Id: data.Id,
          Curated: curated,
        },
      ],
    };
    const { value } = await props.addCuratedTags(payload);
    if (value?.Success) {
      const state = location?.state;
      callApiWithStatus(ltWeeklyDate, selectedAgoloNewsStatus);
    }
  };

  const removeStockPhoto = () => {
    setStockPhoto("");
    setStockPhotoPreview({});
  };

  const removeWeeklyUpdateImage = () => {
    setWeeklyUpdateImage("");
    setWeeklyUpdateImagePreview({});
  };
  const uploadImage = async (
    baseVal,
    extention,
    key,
    fileName = "",
    LtWeeklyId = 0
  ) => {
    let count = imageUploadPendingCount + 1;
    setImageUploadPendingCount(count);
    let payload = {
      Content: baseVal,
      Extention: extention,
      FileName: fileName,
      LtWeeklyId: LtWeeklyId,
      Key: key,
    };
    const { value } = await props.uploadImage(payload);
    if (value) {
      if (key === "Photo") {
        setIntoPhoto(value);
      } else if (key === "WeeklyUpdateImage") {
        setWeeklyUpdateImage(value);
      } else {
        setStockPhoto(value);
      }
      introductionData[key] = value;
      setIntroductionData({ ...introductionData });
      updateIntro(true, value, key);
      let count = imageUploadPendingCount - 1;
      if (count > 0) {
        setImageUploadPendingCount(count);
      } else {
        setImageUploadPendingCount(0);
      }
    }
  };

  const getModifyCuratedList = (value) => {
    const val = value?.split("|");
    let valList = [];
    let prm = val?.map((e, index) => {
      let isChecked = curatedList.filter((obj) => {
        return obj.value?.toString() === e;
      });
      if (isChecked.length > 0) {
        valList.push(isChecked[0]);
      }
    });
    if (val?.length > 0) Promise.all(prm);
    valList = sortBy(valList, "label");
    return valList;
  };

  const handleChange = (e) => {
    setSelectedCuratedValue(e);
  };

  const onEditorIntroChange = (val) => {
    // introductionData.LongDescription = val
    setIntroLongDescription(val);
  };

  const handleIntroChange = (val, key) => {
    introductionData[key] = val;
    setIntroductionData({ ...introductionData });
  };

  const updateLtweeklyData = async (isDeletedstatus = false) => {
    let isError = false;
    let index = ltWeeklyItemData.findIndex((object) => {
      return object.ShortDescription !== "" && object.LongDescription === "";
    });
    let indexNew = ltWeeklyItemData.findIndex((object) => {
      return object.ShortDescription === "" && object.LongDescription !== "";
    });
    if (index > -1) {
      let pay = [
        {
          key: "LongDescription",
          ItemId: ltWeeklyItemData[index].ItemId,
          ErrorMessage: "Please Enter LongDescription",
        },
      ];
      isError = true;
      setErrorList([...pay]);
    }
    if (indexNew > -1) {
      // only splice array when item is found
      let pay = [
        {
          key: "ShortDescription",
          ItemId: ltWeeklyItemData[indexNew].ItemId,
          ErrorMessage: "Please Enter ShortDescription",
        },
      ];
      isError = true;
      setErrorList([...pay]);
    }
    // if (!isError) {
    if (true) {
      setIsLoading(true);
      if (!introductionData.ShowInApp) {
        introductionData.ShowInApp = false;
      }
      if (!introductionData.ShowInWebsite) {
        introductionData.ShowInWebsite = false;
      }
      if (!introductionData.OtherNewsButtonInEmail) {
        introductionData.OtherNewsButtonInEmail = false;
      }
      introductionData["Photo"] = introPhoto;
      introductionData["StockItem"] = stockPhoto;
      introductionData["WeeklyUpdateImage"] = WeeklyUpdateImage;
      if (introductionData.Id === 0) {
        introductionData.Id = 0;
        //introductionData.Created = moment().format("Y-MM-DD HH:mm:ss.SSS Z")
        introductionData.Created = new Date();
        //introductionData.ItemId= "00000000-0000-0000-0000-000000000000"
        introductionData.ItemName = moment(ltWeeklyDate, "MMMM-DD-YYYY").format(
          "MM-DD-YYYY"
        );
        introductionData["LTWeeklyItem"] = ltWeeklyItemData;
        const { value } = await props.AddLTWeekly(introductionData);
        if (value?.Success) {
          setLoader(true);
          setErrorList([]);
          setIsLoading(false);
          if (!isDeletedstatus) {
            openNotificationWithIcon("success", "Data saved successfully.");
          }
          setIntoPhoto("");
          callApiGetLtWeeklyItems(ltWeeklyDate);
        }
      } else {
        if (introductionData?.PdfLinkUrl === null) {
          introductionData.PdfLinkUrl = "";
        }
        let payload = {
          Data: introductionData,
          LtWeeklyItems: ltWeeklyItemData,
        };
        const { value } = await props.updateLtweeklyDetails(payload);
        if (value?.Success) {
          setLoader(true);
          setErrorList([]);
          setIsLoading(false);
          if (!isDeletedstatus) {
            openNotificationWithIcon("success", "Data saved successfully.");
          }
          setIntoPhoto("");
          callApiGetLtWeeklyItems(ltWeeklyDate);
        }
      }
    }
    //   }
    // }
  };

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Liontree",
      description: message,
    });
  };

  const handleChangeData = (key) => {
    return (event, editor) => {
      if (isLtWeeklyData) {
        if (key === "ShortDescription") {
          setAppTitleError("");
        } else if (key === "LongDescription") {
          setIntroductionError("");
        }
        introductionData[key] = editor.getData();
        setIntroductionData({ ...introductionData });
      }
    };
  };

  const handleChangeDetails = (key, value) => {
    introductionData[key] = value;
    setIntroductionData({ ...introductionData });
  };

  const addImages = async (acceptedFiles, keys, keyData) => {
    if (acceptedFiles.length > 0) {
      if (keyData === "StockItem") {
        setIntroStockLoader(true);
      } else if (keyData === "WeeklyUpdateImage") {
        setWeeklyUpdateImageLoader(true);
      } else {
        setIntroImageLoader(true);
      }
      setPhotoError("");
      setImageError("");
      if (acceptedFiles.length > 1) {
        const imageError = "Maximum 1 photo";
        setImageError(imageError);
      } else {
        acceptedFiles.map(async (data, key) => {
          if (data.type.split("/")[0] === "image") {
            let reader = new FileReader();
            reader.onloadend = async () => {
              let baseVal = reader.result
                ?.replaceAll("data:image/png;base64,", "")
                .replaceAll("data:image/jpeg;base64,", "");
              let ext = data.type.split("/")[1];
              let fileName = data.name?.replaceAll(`.${ext}`, "");

              await uploadImage(
                baseVal,
                ext,
                keyData,
                fileName,
                introductionData?.Id
              );
              setIsImageUploaded(true);
              setIntroImageLoader(false);
              setIntroStockLoader(false);
              setWeeklyUpdateImageLoader(false);
            };
            reader.readAsDataURL(acceptedFiles[0]);
            acceptedFiles[key].preview = URL.createObjectURL(data);
            if (keyData === "StockItem") {
              setStockPhotoPreview(data);
            } else if (keyData === "WeeklyUpdateImage") {
              setWeeklyUpdateImagePreview(data);
            } else {
              setIntroImagePreview(data);
            }
          } else {
            setWeeklyUpdateImageLoader(false);
            setIntroStockLoader(false);
            if (keyData === "StockItem") {
              setStockImageError("Please use jpeg or png format.");
            } else if (keyData === "WeeklyUpdateImage") {
              setWeeklyUpdateImageError("Please use jpeg or png format.");
            } else {
              setImageError("Please use jpeg or png format.");
            }
            setIntroImageLoader(false);
          }
        });
      }
    } else {
      if (keyData === "StockItem") {
        setStockImageError("Please use jpeg or png format.");
      } else if (keyData === "WeeklyUpdateImage") {
        setWeeklyUpdateImageError("Please use jpeg or png format.");
      } else {
        setImageError("Please use jpeg or png format.");
      }
    }
  };

  const handleDate = (date) => {
    const str = "Week ending ";
    date = moment(date).format("ll");
    date = str.concat(date);
    return date;
  };

  const sendLtWeeklyEmail = async () => {
    // if(campaignTitle?.trim() === ""){
    //   setCampaignTitleError("Enter Campaign Title")
    //   return
    // }
    // if(userListId === ""){
    //   setMailingListError("Please Select Mailing List")
    //   return
    // }
    setIsSendButtonDisabled(true)
    let hasError = false;

    if (campaignTitle?.trim() === "") {
      setCampaignTitleError("Enter Campaign Title");
      hasError = true;
    } else {
      setCampaignTitleError("");
    }
  
    if (userListId === "") {
      setMailingListError("Please Select Mailing List");
      hasError = true;
    } else {
      setMailingListError("");
    }
  
    if (hasError) {
      setIsSendButtonDisabled(false)
      return;
    }

   
    if (campaignTitle?.trim() !== "") {
      // setIsLoadingEmail(true);
      let date = new Date(ltWeeklyDate);
      date = moment(date, "MM DD YYYY").format("MM-DD-YYYY");
      let payload = {
        CampaignTitle: campaignTitle + "--" + campaignType,
        MailingListId: userListId,
        WeeklyDate: date,
        CampaignType: campaignType,
      };
      const { value } = await props.sendLtWeeklyEmail(payload);
      // setIsLoadingEmail(false);
      if (value?.Success) {
        setCampaignTitle("");
        setUserListId("")
        setCampaignType("Test")
        setCampaignTitleError("")
        setMailingListError("")
        setEmailModal(false);
        openNotificationWithIcon(
          "success",
          "Send LT Weekly Email successfully."
        );
      }else{
        setCampaignTitle("");
        setUserListId("")
        setCampaignType("Test")
        setCampaignTitleError("")
        setMailingListError("")
        setEmailModal(false);
        openNotificationWithIcon(
          "success",
          "IIM Campaign already exist you can not send the mail again."
        );
      }
    } else {
      setCampaignTitleError("Enter Campaign Title.");
      setUserListId("")
      setCampaignType("Test")
      setIsSendButtonDisabled(false)
    }
  };

  const onIntroCheck = (val, key) => {
    introductionData[key] = !introductionData[key];
    setIntroductionData({ ...introductionData });
    setOpenWebsiteModal(false);
    setOpenAppModal(false);
  };

  const handleChangeLtItemData = (data, index, name) => {
    return (event, editor) => {
      if (isLtWeeklyData) {
        if (
          name === "LongDescription" &&
          errorList[0]?.key === "LongDescription"
        ) {
          setErrorList([]);
        }
        if (
          name === "ShortDescription" &&
          errorList[0]?.key === "ShortDescription"
        ) {
          setErrorList([]);
        }
        if (name === "LongDescription") {
          data["LongDescription"] = editor
            .getData()
            ?.replaceAll(`${process.env.REACT_APP_API_HOSTNAME}/`, "")
            ?.replaceAll(
              `${process.env.REACT_APP_API_HOSTNAME}/-/media`,
              "-/media"
            );
          ltWeeklyItemData[index] = data;
          setLtWeeklyItemData([...ltWeeklyItemData]);
        } else {
          data[name] = editor.getData();
          ltWeeklyItemData[index] = data;
          setLtWeeklyItemData([...ltWeeklyItemData]);
        }
      }
    };
  };

  // const syncLtWeeklyToSitecore = async () => {
  //   setIsSyncLtWeeklyLoader(true);
  //   let itemName = moment(new Date(location.pathname.split("/")[2])).format(
  //     "MM-DD-YYYY"
  //   );
  //   const { value } = await props.syncLtWeeklyToSitecore({
  //     ItemName: itemName,
  //   });
  //   setIsSyncLtWeeklyLoader(false);
  //   openNotificationWithIcon(
  //     "success",
  //     "Synced LT Weekly to Sitecore successfully."
  //   );
  // };

  const emailSendLtTeam = async () => {
    const { value } = await props.SendEmailToLtTeam({ date: ltWeeklyDate });
    if (value?.Success) {
      openNotificationWithIcon(
        "success",
        "Email sent to LT team successfully."
      );
    }
  };

  const handleSort = async () => {
    setSort(!sort);
    let value = "asc";
    if (!sort) {
      value = "asc";
    } else {
      value = "desc";
    }
    let d = orderBy(
      agoloNewsData,
      [(data) => data.Category.toLowerCase()],
      [value]
    );
    setAgoloNewsData([...d]);
  };

  const generatePdf = () => {
    // Show the loading spinner
    const HostName = process.env.REACT_APP_API_HOSTNAME;
    setGeneratePdfLoader(true);
    let itemName = moment(new Date(location.pathname.split("/")[2])).format(
      "MM-DD-YYYY"
    );
    const pdfUrl = `${HostName}/Dashboard/pdf?WeeklyDate=${itemName}`;

    // Open the PDF URL in a new window
    window.open(pdfUrl, "_blank");

    // Hide the loading spinner after a short delay (optional)
    setTimeout(() => {
      setGeneratePdfLoader(false);
    }, 1000);
  };
  const formatDate = (dateString) => {
    //   const date = new Date(dateString);
    // const month = date.toLocaleString('en-US', { month: 'long' });
    // const day = date.getDate();
    // const year = date.getFullYear();
    // return `${month.slice(0, 3)}-${day}-${year}`;
    let date = new Date(dateString);
    date = moment(date, "MM DD YYYY").format("MMMM-DD-YYYY");
    return date;
  };
  const updateIntro = async (imageUpload = false, image = "", key = "") => {
   
    if (imageUpload) {
      setIntroLoader(false);
    } else {
      setIntroLoader(true);
    }
    if (!introductionData.ShowInApp) {
      introductionData.ShowInApp = false;
    }
    if (!introductionData.ShowInWebsite) {
      introductionData.ShowInWebsite = false;
    }
    if (key === "Photo") {
      introductionData["Photo"] = image;
    } else if (key === "WeeklyUpdateImage") {
      introductionData["WeeklyUpdateImage"] = image;
    } else if (key !== "") {
      introductionData["StockItem"] = image;
    }
    const { value } = await props.UpdateLtweeklyDetailsAuto(introductionData);

    if (value?.Success) {
      setIntroLoader(false);
      setErrorList([]);

      if (!imageUpload) {
        openNotificationWithIcon("success", "Data saved successfully.");
      }
    }
    // callApiGetLtWeeklyItems(ltWeeklyDate);
  };

  const updateTheme = async (k) => {
    let data = themeLoader;
    data[k] = true;
    setThemeLoader([...data]);

    const payload = {
      Id: ltWeeklyItemData[k].Id,
      ItemId: ltWeeklyItemData[k].ItemId,
      LTweeklyId: ltWeeklyItemData[k].LTweeklyId,
      Created: ltWeeklyItemData[k].Created,
      ItemName: ltWeeklyItemData[k].ItemName,
      Photo: ltWeeklyItemData[k].Photo,
      ShortDescription: ltWeeklyItemData[k].ShortDescription,
      LongDescription: ltWeeklyItemData[k].LongDescription,
      Title: ltWeeklyItemData[k].Title,
    };
    const { value } = await props.UpdateLtweeklyItem(payload);
    if (value?.Success) {
      // setLoader(true);
      setErrorList([]);
      data[k] = false;
      setThemeLoader([...data]);
      // setIsLoading(false);
      // if (!isDeletedstatus) {
      openNotificationWithIcon("success", "Data saved successfully.");
      // }
      // callApiGetLtWeeklyItems(ltWeeklyDate);
    }
  };

  const handleAnswerChange = (index, e) => {
    setOptionError("");
    const updatedOptions = [...options];
    updatedOptions[index].value = e.target.value;
    setOptions(updatedOptions);
  };
  const handleSaveQuestionAnswer = async () => {
    setPollSaveLoader(true);
    let payload = {};
    if (!isEmpty(pollDataById)) {
      payload = {
        Id: pollDataById?.Id,
        Question: question ? question : "",
        Option1: options[0]?.value ? options[0].value : "",
        Option2: options[1]?.value ? options[1].value : "",
        Option3: options[2]?.value ? options[2].value : "",
        Option4: options[3]?.value ? options[3].value : "",
        Answer: correctAnswer
          ? !iscorrectAnswerExist
            ? ""
            : correctAnswer
          : "",
        LtWeeklyId: introductionData?.Id,
      };
    } else {
      payload = {
        Question: question ? question : "",
        Option1: options[0]?.value ? options[0].value : "",
        Option2: options[1]?.value ? options[1].value : "",
        Option3: options[2]?.value ? options[2].value : "",
        Option4: options[3]?.value ? options[3].value : "",
        Answer: correctAnswer
          ? !iscorrectAnswerExist
            ? ""
            : correctAnswer
          : "",
        LtWeeklyId: introductionData?.Id,
      };
    }
    if (!question || question === "") {
      setPollSaveLoader(false);
      setQuestionError("Please enter question.");
      return;
    }
    if (options[0]?.value === "" || options[1]?.value === "") {
      setPollSaveLoader(false);
      // openNotificationWithIcon('error', 'Please enter atleast two options.');
      setOptionError("Please enter atleast two options.");
      return;
    }
    if (iscorrectAnswerExist && correctAnswer=="") {
      setPollSaveLoader(false);
      setOptionError("Please select at least one correct answer.");
      return;
    }
    const { value } = await props.AddPoll(payload);
    if (value?.Success) {
      setPollSaveLoader(false);
      callApiPollDataById();
      openNotificationWithIcon("success", "Data saved successfully.");
    }
  };
  
  const handleQuestionChange = (e) => {
    setQuestionError("");
    setQuestion(e.target.value);
  };
  const addOptions = (e, index) => {
    const newOption = {
      id: options.length + 1,
      value: "",
      label: `Option ${options.length + 1}`,
    };
    setOptions([...options, newOption]);
  };
  const removeOptions = () => {
    const updatedOptions = [...options];
    updatedOptions.pop(); // Remove the last option
    setOptions(updatedOptions);
  };
  const handleCorrectAnswer = (e, index) => {
    setCorrectAnswer(e.target.value);
    if (e.target.checked && !checkboxChecked) {
      setCheckboxChecked(true);
      setOptionError("")
    }
  };
  const callApiPollData = async () => {
    const payload = {
      Page: 1,
      Count: 50,
      LtweeklyId: introductionData?.Id,
    };
    if (!isMounted) {
      const { value } = await props.GetPollUserDetails(payload);
      if (value?.Success) {
        setPollData(get(value, "Result.UserDetails", null));
        setTotalPollResultCount(value?.Result?.TotalCount);
        setPollResultData(get(value, "Result.PollResult", null));
        setCorrectPollAnswer(
          get(value, "Result.PollResult[0].correctAnswer", "")
        );
        let nextPageCount = parseInt(Math.ceil(value?.Result?.TotalCount / 50));
        setNextStep(nextPageCount);
      }
    }
    setIsMounted(true);
  };
  const callApiPollDataById = async () => {
    const payload = {
      LtweeklyId: introductionData?.Id,
    };
    const { value } = await props.GetPollByLtweeklyId(payload);
    if (value?.Success) {
      setPollDataById(get(value, "Result", null));
    }
    if (!isEmpty(value?.Result)) {
      setQuestion(value?.Result?.Question);
      if(value?.Result?.Answer==="1"){
      setCorrectAnswer(value?.Result?.Option1);
      }
      else if(value?.Result?.Answer==="2"){
        setCorrectAnswer(value?.Result?.Option2);
        }
        else if(value?.Result?.Answer==="3"){
          setCorrectAnswer(value?.Result?.Option3);
          }
          else {
            setCorrectAnswer(value?.Result?.Option4);
            }
      if(value?.Result?.Answer){
      setIsCorrectAnswerExist(true)
      localStorage.setItem("iscorrectAnswerExist", "true");
      }else{
        setIsCorrectAnswerExist(false)
        localStorage.setItem("iscorrectAnswerExist", "false");
      }
      let updatedOptions = [
        { id: 1, value: value?.Result?.Option1, label: "Option 1" },
        { id: 2, value: value?.Result?.Option2, label: "Option 2" },
      ];
      if (value?.Result?.Option3 !== null && value?.Result?.Option3 !== "") {
        updatedOptions.push({
          id: 3,
          value: value?.Result?.Option3,
          label: "Option 3",
        });
      }
      if (value?.Result?.Option4 !== null && value?.Result?.Option4 !== "") {
        updatedOptions.push({
          id: 4,
          value: value?.Result?.Option4,
          label: "Option 4",
        });
      }

      setOptions(updatedOptions);
    }
  };

  const pageChange = async (key) => {
    setLoader(true);
    if (key === "next") {
      let pageNum = pageSearchNumber + 1;
      setPageSearchNumber(pageNum);
      let payload = {};
      payload = {
        Page: pageNum,
        Count: 50,
        LtweeklyId: introductionData?.Id,
        // searchText: " "
      };
      const { value } = await props.GetPollUserDetails(payload);
      if (value?.Success) {
        setLoader(false);
        if (value?.Result?.UserDetails.length > 0) {
          setPollData([...value?.Result?.UserDetails]);
          setTotalPollResultCount(value?.Result?.TotalCount);
          let nextPageCount = parseInt(Math.ceil(totalPollResultCount / 50));
          setNextStep(nextPageCount);
        } else {
          setPollData([]);
          setTotalPollResultCount(0);
        }
      } else {
        setPollData([]);
        setTotalPollResultCount(0);
        setLoader(false);
      }
    } else if (key === "previous") {
      let pageNum = pageSearchNumber - 1 === 0 ? 1 : pageSearchNumber - 1;
      setPageSearchNumber(pageNum);
      let payload = {};
      payload = {
        Page: pageNum,
        Count: 50,
        LtweeklyId: introductionData?.Id,
      };

      const { value } = await props.GetPollUserDetails(payload);
      if (value?.Success) {
        if (value?.Result?.UserDetails.length > 0) {
          setPollData([...value?.Result?.UserDetails]);
          setTotalPollResultCount(value?.Result?.TotalCount);
          let nextPageCount = parseInt(Math.ceil(totalPollResultCount / 50));
          setNextStep(nextPageCount);
        } else {
          setPollData([]);
          setTotalPollResultCount(0);
        }
        setLoader(false);
      } else {
        setPollData([]);
        setTotalPollResultCount(0);
        setLoader(false);
      }
    }
    setLoader(false);
  };

  useEffect(() => {
    if (introductionData && introductionData.Id && !IscalledPollAPI) {
      setIsCalledPollApi(true);
      callApiPollData();
      callApiPollDataById();
    }
  }, [introductionData]);

  const getConvertTimeToEast = (date) => {
    if (typeof date === "string" && !date?.includes("Z")) {
      date = date + "Z";
    }
    const easternTime = moment(date)
      .tz("America/New_York")
      .format("MM/DD/yyyy hh:mm A");
    return easternTime;
  };
  const calculatePercentage = (count) => {
    return totalPollResultCount > 0
      ? ((count / totalPollResultCount) * 100).toFixed(2)
      : "";
  };
  const handleCorrectAnswerCheck = (e) => {
    const isChecked = e.target.checked;
    setIsCorrectAnswerExist(isChecked);
    localStorage.setItem("iscorrectAnswerExist", isChecked ? "true" : "false");
  };
  return (
    <>
      <main
        className="main viewother__news-wrapper"
        style={{ paddingBottom: "90px" }}
        role="head"
      >
        <div className="main__header">
          <header className="head">
            <div className="head__container">
              <div className="head__row">
                <div className="head__left">
                  <div className="head__close">
                    <button
                      onClick={() => navigate("/iim-weeklies")}
                      className="btn btn__close"
                    >
                      <Close />
                    </button>
                  </div>
                  <div className="head__divider"></div>
                  <div className="head__title">{handleDate(date)}</div>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div className="tabs__links tabs__links--space">
          <button
            className={tab === "theme" ? "tabs__link active" : "tabs__link"}
            onClick={() => changeTab("theme")}
          >
            Themes
          </button>
          <button
            className={
              tab === "other-news" ? "tabs__link active" : "tabs__link"
            }
            onClick={() => changeTab("other-news")}
          >
            Other News
          </button>
          {introductionData?.Id != 0 && (
            <>
              <button
                className={tab === "poll" ? "tabs__link active" : "tabs__link"}
                onClick={() => changeTab("poll")}
              >
                Poll
              </button>
              <button
                className={
                  tab === "poll results" ? "tabs__link active" : "tabs__link"
                }
                onClick={() => changeTab("poll results")}
              >
                Poll Results
              </button>
            </>
          )}
        </div>
        {tab === "other-news" && (
          <div className="custom__container">
            <div className="all__slect ps-0 justify-content-between">
              <div className="d-flex">
                <button
                  className="btn btn__purple ms-0"
                  onClick={() =>
                    navigate(`/add-news-articles/${ltWeeklyDate}`, {
                      state: date,
                    })
                  }
                >
                  Add News Articles
                </button>
                <button
                  className="btn btn__purple"
                  onClick={() =>
                    navigate(`/edit-news-articles/${ltWeeklyDate}`, {
                      state: date,
                    })
                  }
                >
                  Edit News Articles
                </button>
                {(articleStatus === "all" || !articleStatus) && (
                  <div style={{ marginLeft: "15px" }}>
                    <Select
                      defaultValue={[
                        StatusOptions[1],
                        StatusOptions[3],
                        StatusOptions[2],
                      ]}
                      isMulti
                      className="react-select-container"
                      placeholder="All Status"
                      isClearable={false}
                      name="status"
                      closeMenuOnSelect={false}
                      onChange={(e) => handleStatus(e)}
                      options={StatusOptions}
                    />
                  </div>
                )}
                {articleStatus === "completed" && (
                  <div style={{ marginLeft: "15px" }}>
                    <Select
                      defaultValue={[StatusOptions[3]]}
                      isMulti
                      className="react-select-container"
                      placeholder="All Status"
                      isClearable={false}
                      name="status"
                      closeMenuOnSelect={false}
                      onChange={(e) => handleStatus(e)}
                      options={StatusOptions}
                    />
                  </div>
                )}
                {articleStatus === "draft" && (
                  <div style={{ marginLeft: "15px" }}>
                    <Select
                      defaultValue={[StatusOptions[1]]}
                      isMulti
                      className="react-select-container"
                      placeholder="All Status"
                      isClearable={false}
                      name="status"
                      closeMenuOnSelect={false}
                      onChange={(e) => handleStatus(e)}
                      options={StatusOptions}
                    />
                  </div>
                )}
                {articleStatus === "saved" && (
                  <div style={{ marginLeft: "15px" }}>
                    <Select
                      defaultValue={[StatusOptions[2]]}
                      isMulti
                      className="react-select-container"
                      placeholder="All Status"
                      isClearable={false}
                      name="status"
                      closeMenuOnSelect={false}
                      onChange={(e) => handleStatus(e)}
                      options={StatusOptions}
                    />
                  </div>
                )}

                <div
                  style={{ marginLeft: "15px" }}
                  className="d-flex align-items-center"
                >
                  <label className="me-2 custom-label">Date Added</label>
                  <Select
                    defaultValue={{ value: "All", label: "All" }}
                    // value ={groupDates.find(option => option.value === selectedDate)}
                    className="react-select-container min-width-select"
                    placeholder="All News"
                    name="newsArticle"
                    //  options={groupDates}
                    options={[{ value: "All", label: "All" }, ...groupDates]}
                    onChange={handleDateSelection}
                  />
                </div>
              </div>
              <div>
                <button
                  className="btn btn__purple"
                  disabled={!isSavedDataExist ? true : false}
                  onClick={() => emailSendLtTeam()}
                >
                  Email LT Team
                </button>
              </div>
            </div>
            {/*            <div className="table-responsive table__responsive--custom">*/}
            <div className="table-responsive table__responsive--custom">
              <table className="table table__custom table-other-news">
                <thead>
                  <tr>
                    {/* <th className="table__selection--column">Description</th> */}
                    <th className=" ps-0">Article</th>
                    {/* <th>Source</th> */}
                    <th style={{ paddingRight: "30px", paddingLeft: "30px" }}>
                      {sort ? (
                        <button
                          className="sort-btn"
                          onClick={() => {
                            handleSort(!sort);
                            // callApi(location?.pathname?.split("/")[2]?.replaceAll("_"," ")?.replaceAll("--","/"), !sort)
                          }}
                        >
                          Primary Tag
                          <ArrowUpOutlined />
                        </button>
                      ) : (
                        <button
                          className="sort-btn"
                          onClick={
                            () => {
                              handleSort(!sort);
                            }
                            // callApi(location?.pathname?.split("/")[2]?.replaceAll("_"," ")?.replaceAll("--","/"), !sort)}
                          }
                        >
                          Primary Tag
                          <ArrowDownOutlined />
                        </button>
                      )}
                    </th>
                    <th style={{ paddingRight: "30px", paddingLeft: "30px" }}>
                      Secondary Tag
                    </th>
                    <th style={{ paddingRight: "30px", paddingLeft: "30px" }}>
                      Curated List
                    </th>
                    <th style={{ paddingRight: "30px", paddingLeft: "30px" }}>
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <Spin
                      size="large"
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 30, color: "#000" }}
                          spin
                        />
                      }
                      className="loader__full"
                    />
                  ) : (
                    agoloNewsData?.length > 0 &&
                    agoloNewsData?.map((an, i) => {
                      return (
                        <tr key={i}>
                          <td className="table-description  ps-0">
                            <th className="">Title</th>
                            <tr>{an.Title}</tr>
                            <br />
                            <th className="">Description</th>
                            <tr>
                              <div
                                className={
                                  indexes.includes(i) ? "" : "content-hide"
                                }
                                dangerouslySetInnerHTML={{
                                  __html: addBrTag(an.Description),
                                }}
                              />
                              {!indexes.includes(i) ? (
                                <button
                                  onClick={() => showFullDescriptionHandler(i)}
                                  className="read-btn"
                                >
                                  See More
                                </button>
                              ) : (
                                <button
                                  onClick={() => showFullDescriptionHandler(i)}
                                  className="read-btn"
                                >
                                  See Less
                                </button>
                              )}
                            </tr>
                            <br />
                            <th className="">Source</th>
                            <tr className="add-btn">
                              <a
                                className="add-btn"
                                target={"_blank"}
                                href={an.Url}
                              >
                                {an.Source.toUpperCase()}
                              </a>
                            </tr>
                            <br />
                          </td>
                          {/* <label className="custom__checkbox">
                            <input
                              type="checkbox"
                              className="custom__checkbox--input"
                              name="duplex"
                              value={i}
                              checked={
                                selectedNews
                                  ?.map((e) => e.Id)
                                  ?.indexOf(an.Id) !== -1
                              }
                              onChange={() => onCheck(an)}
                            />
                            <span className="custom__checkbox--check"></span>
                            {an.Description}
                          </label> */}
                          {/* <td
                          style={{
                            "max-width": "340px",
                            "min-width": "150px",
                            "white-space": "break-spaces",
                          }}
                        >
                          {an.Source}
                        </td> */}
                          <td
                            style={{
                              paddingRight: "30px",
                              paddingLeft: "30px",
                            }}
                          >
                            {an.Category == null || an.Category == ""
                              ? "-"
                              : an.Category?.split("|")[0]}
                          </td>
                          <td
                            style={{
                              paddingRight: "30px",
                              paddingLeft: "30px",
                            }}
                          >
                            {an.SecondaryCategory == null ||
                            an.SecondaryCategory == ""
                              ? "-"
                              : an.SecondaryCategory !== "" &&
                                an.SecondaryCategory
                              ? an.SecondaryCategory?.split("|")?.map((sc) => {
                                  return <p>{sc}</p>;
                                })
                              : an.Category?.split("|")?.map((sc, i) => {
                                  if (i > 0) {
                                    return <p>{sc}</p>;
                                  }
                                })}
                          </td>
                          <td
                            style={{
                              paddingRight: "30px",
                              paddingLeft: "30px",
                            }}
                          >
                            {an.Curated == null
                              ? "-"
                              : getModifyCuratedList(an.Curated).map((item) => {
                                  return item.label;
                                })}
                          </td>
                          <td
                            style={{
                              paddingRight: "30px",
                              paddingLeft: "30px",
                            }}
                            className="table__status"
                          >
                            {an.Status == null
                              ? "-"
                              : an.Status === "Pending"
                              ? "Draft"
                              : an.Status === "Completed"
                              ? "Approved"
                              : an.Status}
                          </td>
                          <td
                            style={{
                              paddingRight: "30px",
                              paddingLeft: "30px",
                            }}
                          >
                            <button
                              onClick={() => {
                                setDeleteNewsModal(true);
                                setDeletedAgoloNews(an);
                              }}
                              className="remove-btn"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
            {agoloNewsData?.length === 0 && !loader && (
              <div className="table__blank">
                <div className="table__blank--text">No Data</div>
              </div>
            )}
          </div>
        )}
        {tab === "theme" && (
          <>
            {loader && (
              <Spin
                size="large"
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 30, color: "#000" }}
                    spin
                  />
                }
                className="loader__full"
              />
            )}
            <div className="custom__container" style={{ maxWidth: 752 }}>
              <div className="d-flex justify-content-between flex-wrap gap-3">
                <div className="d-flex flex-column gap-3 mb-3">
                  <label className="custom__checkbox">
                    <input
                      name="ShowInWebsite"
                      value={introductionData?.ShowInWebsite}
                      checked={introductionData?.ShowInWebsite}
                      // onChange={(e) =>
                      //   onIntroCheck(e.target.value, "ShowInWebsite")
                      // }
                      onClick={(e) =>
                        !introductionData?.ShowInWebsite
                          ? setOpenWebsiteModal(true)
                          : onIntroCheck(e.target.value, "ShowInWebsite")
                      }
                      type="checkbox"
                      className="custom__checkbox--input"
                    />
                    <span className="custom__checkbox--check"></span>
                    Show on LT Weekly Website
                    <br />
                    <strong>
                      (If selected toggle button this LT Weekly will be shown in
                      the Website)
                    </strong>
                  </label>
                  <label className="custom__checkbox">
                    <input
                      name="ShowInApp"
                      value={introductionData?.ShowInApp}
                      checked={introductionData?.ShowInApp}
                      // onChange={(e) => onIntroCheck(e.target.value, "ShowInApp")}
                      onClick={(e) =>
                        !introductionData?.ShowInApp
                          ? setOpenAppModal(true)
                          : onIntroCheck(e.target.value, "ShowInApp")
                      }
                      type="checkbox"
                      className="custom__checkbox--input"
                    />
                    <span className="custom__checkbox--check"></span>
                    Show in app
                    <br />
                    <strong>
                      (If selected toggle button this LT Weekly will be shown in
                      the App )
                    </strong>
                  </label>
                  <label className="custom__checkbox">
                    <input
                      name="ShowSubscriptionInEmail"
                      value={introductionData?.ShowSubscriptionInEmail}
                      checked={introductionData?.ShowSubscriptionInEmail}
                      onChange={(e) =>
                        onIntroCheck(e.target.value, "ShowSubscriptionInEmail")
                      }
                      type="checkbox"
                      className="custom__checkbox--input"
                    />
                    <span className="custom__checkbox--check"></span>
                    Subscription Sign up Request
                  </label>
                  <label className="custom__checkbox">
                    <input
                      name="ShowSignatureInEmail"
                      value={introductionData?.ShowSignatureInEmail}
                      checked={introductionData?.ShowSignatureInEmail}
                      onChange={(e) =>
                        onIntroCheck(e.target.value, "ShowSignatureInEmail")
                      }
                      type="checkbox"
                      className="custom__checkbox--input"
                    />
                    <span className="custom__checkbox--check"></span>
                    Signature
                  </label>
                  <label className="custom__checkbox">
                    <input
                      name="ShowDownloadPDFInEmail"
                      value={introductionData?.ShowDownloadPDFInEmail}
                      checked={introductionData?.ShowDownloadPDFInEmail}
                      onChange={(e) =>
                        onIntroCheck(e.target.value, "ShowDownloadPDFInEmail")
                      }
                      type="checkbox"
                      className="custom__checkbox--input"
                    />
                    <span className="custom__checkbox--check"></span>
                    Download PDF
                  </label>
                  {/* <label className="custom__checkbox">
                    <input
                      name="OtherNewsButtonInEmail"
                      value={introductionData?.OtherNewsButtonInEmail}
                      checked={introductionData?.OtherNewsButtonInEmail}
                      onChange={(e) =>
                        onIntroCheck(e.target.value, "OtherNewsButtonInEmail")
                      }
                      type="checkbox"
                      className="custom__checkbox--input"
                    />
                    <span className="custom__checkbox--check"></span>
                    Show View Other News on Web
                  </label> */}
                  </div>
                  
                {introductionData.LongDescription && (
                  <div className="d-flex flex-column  gap-3">
                    <button
                      onClick={() => {
                        setEmailModal(!emailModal);
                        setIsSendButtonDisabled(false)
                        setCampaignType("Test")
                        setIsLive(false)
                        setUserListId("")
                        setMailingListData([])
                        CallApiForGetMailingList();
                      }}
                      type="button"
                      className="btn btn__purple"
                    >
                      Send LT Weekly Email
                    </button>
                    <button
                      onClick={() => {
                        generatePdf();
                        setGeneratePdfLoader(true);
                        // CallApiForGetMailingList();
                      }}
                      type="button"
                      className="btn btn__purple"
                    >
                      {generatePdfLoader ? (
                        <Spin indicator={antIcon} />
                      ) : (
                        "Generate LT Weekly PDF"
                      )}
                    </button>
                    <button
                      // onClick={() => {
                      //   syncLtWeeklyToSitecore();
                      // }}
                      type="button"
                      className="btn btn__purple"
                    >
                      {isSyncLtWeeklyLoader ? (
                        <Spin indicator={antIcon} />
                      ) : (
                        "Sync LT weekly To Sitecore"
                      )}
                    </button>
                  </div>
                )}
              </div>
              <div className="other-news-radio">
                  {mailOtherNewsOptions.map((j) => {
                  return (
                  <label className="custom__radio">
                        {" "}
                        {j.label}
                        <input
                          className="custom__radio--input"
                          type="radio"
                          checked={introductionData?.EmailTemplate === j.value}
                          onChange={() => {
                            handlemailotherNewsOptionsType(j.value)
                          }}
                        />
                        <span className="custom__radio--check"></span>
                      </label>
                  )})}
                </div>
              <div className="form-group">
                <label className="label-primary mb-1">Email Subject</label>
                <div className="form-group mt-2">
                  <input
                    type="text"
                    className="form-control"
                    value={introductionData?.EmailSubject}
                    onChange={(e) =>
                      handleChangeDetails("EmailSubject", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="label-primary">App Title</label>
                <div className="editor-instance-new mt-2">
                  <CKEditor
                    editor={Editor}
                    data={introductionData?.ShortDescription}
                    config={custom_config}
                    onChange={handleChangeData("ShortDescription")}
                  />
                </div>
                {appTitleError !== "" && (
                  <>
                    <div className="invalid-feedback">{appTitleError}</div>
                  </>
                )}
              </div>
              {introductionData.Id !== 0 && (
                <div className="form-group">
                  <label className="label-primary">PDF Link</label>
                  <div className="form-group mt-2">
                    <input
                      type="text"
                      className="form-control"
                      value={introductionData?.PdfLinkUrl}
                      onChange={(e) =>
                        handleChangeDetails("PdfLinkUrl", e.target.value)
                      }
                    />
                  </div>
                </div>
              )}
              <div className="form-group" style={{ maxWidth: 404 }}>
                <label className="label-primary">Theme Photo</label>
                <Dropzone
                  accept="image/*"
                  multiple={false}
                  onDrop={(acceptedFiles) =>
                    addImages(acceptedFiles, "Intro", "Photo")
                  }
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="dropzone">
                      <div className="dropzone__area" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <button type="button" className="btn btn__purple">
                          {introImageLoader ? (
                            <Spin indicator={antIcon} />
                          ) : (
                            <>
                              <Plus className="btn__icon" /> Upload Photo
                            </>
                          )}
                        </button>
                      </div>
                    </section>
                  )}
                </Dropzone>
                {introPhoto !== "" && introPhoto !== null && (
                  <div className="dropzone__grid">
                    <div className="dropzone__grid--item" style={{ width: 90 }}>
                      <div className="dropzone__grid--preview">
                        {!isEmpty(introImagePreview) &&
                        get(introImagePreview, "preview", "") ? (
                          <img
                            className="preview__thumb"
                            src={get(introImagePreview, "preview", "")}
                            alt=""
                          />
                        ) : introPhoto?.includes("blob.core.windows.net") ? (
                          <img
                            className="preview__thumb"
                            src={introPhoto}
                            alt=""
                          />
                        ) : (
                          <img
                            className="preview__thumb"
                            src={`${HOSTNAME}` + introPhoto}
                            alt=""
                          />
                        )}

                        <div className="dropzone__grid--remove">
                          <button
                            onClick={() => removeIntroPhoto()}
                            className="btn btn__remove"
                          >
                            <Remove />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {imageError !== "" && (
                  <>
                    <div className="invalid-feedback">{imageError}</div>
                  </>
                )}
                {photoError !== "" && (
                  <>
                    <div className="invalid-feedback">{photoError}</div>
                  </>
                )}
              </div>
              <div className="form-group" style={{ maxWidth: 404 }}>
                <label className="label-primary">Stock Photo</label>
                <Dropzone
                  accept="image/*"
                  multiple={false}
                  onDrop={(acceptedFiles) =>
                    addImages(acceptedFiles, "Intro", "StockItem")
                  }
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="dropzone">
                      <div className="dropzone__area" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <button type="button" className="btn btn__purple">
                          {introStockImageLoader ? (
                            <Spin indicator={antIcon} />
                          ) : (
                            <>
                              <Plus className="btn__icon" /> Upload Photo
                            </>
                          )}
                        </button>
                      </div>
                    </section>
                  )}
                </Dropzone>
                {stockPhoto !== "" && stockPhoto !== null && (
                  <div className="dropzone__grid">
                    <div className="dropzone__grid--item" style={{ width: 90 }}>
                      <div className="dropzone__grid--preview">
                        {!isEmpty(introStockImagePreview) &&
                        get(introStockImagePreview, "preview", "") ? (
                          <img
                            className="preview__thumb"
                            src={get(introStockImagePreview, "preview", "")}
                            alt=""
                          />
                        ) : stockPhoto?.includes("blob.core.windows.net") ? (
                          <img
                            className="preview__thumb"
                            src={stockPhoto}
                            alt=""
                          />
                        ) : (
                          <img
                            className="preview__thumb"
                            src={`${HOSTNAME}` + stockPhoto}
                            alt=""
                          />
                        )}

                        <div className="dropzone__grid--remove">
                          <button
                            onClick={() => removeStockPhoto()}
                            className="btn btn__remove"
                          >
                            <Remove />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {stockImageError !== "" && (
                  <>
                    <div className="invalid-feedback">{stockImageError}</div>
                  </>
                )}
              </div>
              <div className="form-group" style={{ maxWidth: 404 }}>
                <label className="label-primary">Header Photo</label>
                <Dropzone
                  accept="image/*"
                  multiple={false}
                  onDrop={(acceptedFiles) =>
                    addImages(acceptedFiles, "Intro", "WeeklyUpdateImage")
                  }
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="dropzone">
                      <div className="dropzone__area" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <button type="button" className="btn btn__purple">
                          {WeeklyUpdateImageLoader ? (
                            <Spin indicator={antIcon} />
                          ) : (
                            <>
                              <Plus className="btn__icon" /> Upload Photo
                            </>
                          )}
                        </button>
                      </div>
                    </section>
                  )}
                </Dropzone>
                {WeeklyUpdateImage !== "" && WeeklyUpdateImage !== null && (
                  <div className="dropzone__grid">
                    <div className="dropzone__grid--item" style={{ width: 90 }}>
                      <div className="dropzone__grid--preview">
                        {!isEmpty(WeeklyUpdateImagePreview) &&
                        get(WeeklyUpdateImagePreview, "preview", "") ? (
                          <img
                            className="preview__thumb"
                            src={get(WeeklyUpdateImagePreview, "preview", "")}
                            alt=""
                          />
                        ) : WeeklyUpdateImage?.includes(
                            "blob.core.windows.net"
                          ) ? (
                          <img
                            className="preview__thumb"
                            src={WeeklyUpdateImage}
                            alt=""
                          />
                        ) : (
                          <img
                            className="preview__thumb"
                            src={`${HOSTNAME}` + WeeklyUpdateImage}
                            alt=""
                          />
                        )}

                        <div className="dropzone__grid--remove">
                          <button
                            onClick={() => removeWeeklyUpdateImage()}
                            className="btn btn__remove"
                          >
                            <Remove />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {WeeklyUpdateImageError !== "" && (
                  <>
                    <div className="invalid-feedback">
                      {WeeklyUpdateImageError}
                    </div>
                  </>
                )}
              </div>
              <div className="form-group">
                <label className="label-primary">KindredCast Text</label>
                <div className="editor-instance">
                  <CKEditor
                    editor={Editor}
                    data={
                      introductionData?.KindredCast !== null
                        ? introductionData?.KindredCast
                        : ""
                    }
                    config={custom_config}
                    onChange={handleChangeData("KindredCast")}
                    onInit={(editor) => {
                      editor.ui
                        .getEditableElement()
                        .parentElement.insertBefore(
                          editor.ui.view.toolbar.element,
                          editor.ui.getEditableElement()
                        );
                    }}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <label className="label-primary">
                    Introduction: {introductionData?.ItemId}
                  </label>
                  <button
                    onClick={() => {
                      updateIntro();
                    }}
                    className="btn btn__purple ms-0"
                  >
                    {introLoader ? <Spin indicator={antIconOne} /> : "Save"}
                  </button>
                </div>

                <div className="editor-instance">
                  <CKEditor
                    editor={Editor}
                    data={introductionData?.LongDescription}
                    config={custom_config}
                    onChange={handleChangeData("LongDescription")}
                    onInit={(editor) => {
                      editor.ui
                        .getEditableElement()
                        .parentElement.insertBefore(
                          editor.ui.view.toolbar.element,
                          editor.ui.getEditableElement()
                        );
                    }}
                  />
                </div>
                {introductionError !== "" && (
                  <>
                    <div className="invalid-feedback">
                      Please enter introduction
                    </div>
                  </>
                )}
              </div>
              {ltWeeklyItemData?.length > 0 &&
                ltWeeklyItemData?.map((d, k) => (
                  <>
                    <div className="form-group">
                      <div className="label-container-inline-btn">
                        <label className="label-primary">
                          Theme Title {k + 1}: {process.env.REACT_APP_SITE_URL}
                          /LTWeekly/{formatDate(ltWeeklyDate)}/{d?.ItemId}
                        </label>
                        {k === ltWeeklyItemData?.length - 1 && (
                          <button
                            onClick={() => {
                              setDeleteModal(true);
                              setDeletedRecord(k);
                            }}
                            className="remove-btn"
                          >
                            Remove Theme
                          </button>
                        )}
                        <button
                          onClick={() => {
                            updateTheme(k);
                          }}
                          className="btn btn__purple ms-0"
                        >
                          {themeLoader[k] ? (
                            <Spin indicator={antIconOne} />
                          ) : (
                            "Save"
                          )}
                        </button>
                      </div>
                      <div className="editor-instance-new">
                        <CKEditor
                          editor={Editor}
                          data={d?.ShortDescription}
                          config={custom_config}
                          onInit={(editor) => {
                            editor.ui
                              .getEditableElement()
                              .parentElement.insertBefore(
                                editor.ui.view.toolbar.element,
                                editor.ui.getEditableElement()
                              );
                          }}
                          onChange={handleChangeLtItemData(
                            d,
                            k,
                            "ShortDescription"
                          )}
                        />
                        {errorList[0]?.ItemId === d?.ItemId &&
                          errorList[0]?.key === "ShortDescription" && (
                            <>
                              <div className="invalid-feedback">
                                {errorList[0].ErrorMessage}
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="label-primary">Theme Content</label>
                      <div className="editor-instance">
                        <CKEditor
                          editor={Editor}
                          data={d?.LongDescription?.replaceAll(
                            "-/media",
                            `${process.env.REACT_APP_API_HOSTNAME}/-/media`
                          )}
                          config={custom_config}
                          onInit={(editor) => {
                            editor.ui
                              .getEditableElement()
                              .parentElement.insertBefore(
                                editor.ui.view.toolbar.element,
                                editor.ui.getEditableElement()
                              );
                          }}
                          onChange={handleChangeLtItemData(
                            d,
                            k,
                            "LongDescription"
                          )}
                        />
                        {errorList[0]?.ItemId === d?.ItemId &&
                          errorList[0]?.key === "LongDescription" && (
                            <>
                              <div className="invalid-feedback">
                                {errorList[0].ErrorMessage}
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                    {ltWeeklyItemData?.length - 1 > k && <hr />}
                  </>
                ))}
              {ltWeeklyItemData?.length > 0 && <hr />}
              <button
                type="submit"
                onClick={() => addNewTheme()}
                class="btn btn-lg btn__purple w-100"
              >
                Add Theme
              </button>
              {/*            <hr/>
              <button type="submit" class="btn btn-lg btn__purple w-100">Add Theme</button>*/}
            </div>
            <div className={s.ThemeFooter}>
              <button
                disabled={imageUploadPendingCount > 0 ? true : false}
                onClick={() => updateLtweeklyData()}
                className={`btn ${s.BtnSave} ${s.DarkDisabled}`}
              >
                {isLoading ? <Spin indicator={antIconOne} /> : "Save"}
              </button>
            </div>
          </>
        )}
        {tab === "poll" && (
          <div className="custom__container">
            <div className="poll-wrapper">
              <ul className="pole-form-list">
                <li>
                  <label>Question</label>
                  <textarea
                    className="textara-style"
                    value={question}
                    onChange={(e) => handleQuestionChange(e)}
                  ></textarea>

                  {questionError && questionError !== "" && (
                    <>
                      <div className="invalid-feedback">{questionError}</div>
                    </>
                  )}
                </li>
                <li style={{ display: "flex", alignItems: "center" }}>
                  <label className="mb-0 custom__checkbox">
                    <input
                      type="checkbox"
                      name="correctAnswer"
                      className="custom__checkbox--input"
                      // checked={
                      //   iscorrectAnswerExist
                      //     ? iscorrectAnswerExist
                      //     : correctAnswer
                      //     ? true
                      //     : false
                      // }
                      checked={iscorrectAnswerExist}
                      onChange={handleCorrectAnswerCheck}
                    />
                    <span className="custom__checkbox--check"></span>
                    Is there a correct answer?
                  </label>
                </li>

                {options.map((option, index) => (
                  <li key={index}>
                    <label className="d-flex align-items-center justify-content-between">
                      {options[index].label}
                      {iscorrectAnswerExist && index === 0 && (
                        <span>Correct Answer</span>
                      )}
                    </label>
                    <div className="d-flex justify-content-between align-items-center">
                      <input
                        type="text"
                        className="input-style"
                        value={options[index].value ? options[index].value : ""}
                        onChange={(e) => handleAnswerChange(index, e)}
                      />

                      <div className="action-bars">
                        {index == options.length - 1 && ( // Allow removal only for options 3 and beyond
                          <button className="TrashBtn" onClick={removeOptions}>
                            <img
                              src={trashIcon}
                              alt={`Remove Option ${index}`}
                            />
                          </button>
                        )}
                        {iscorrectAnswerExist && (
                          <div className="ms-2">
                            <input
                              type="radio"
                              name="radio-group"
                              id={index}
                              value={option.value !== "" ? option.value : ""}
                              checked={(iscorrectAnswerExist&&correctAnswer!=="")?
                                 option.value === correctAnswer:false
                                 
                              }
                              
                              onChange={(e) => handleCorrectAnswer(e)}
                            />
                            <label for={index}></label>
                          </div>
                        )}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              {optionError && optionError !== "" && (
                <>
                  <div className="invalid-feedback errors-msg">
                    {optionError}
                  </div>
                </>
              )}
              {options.length < 4 && (
                <div className="text-left w-100 mb-42">
                  <button
                    className="btn btn__purple btn__subscribe"
                    onClick={addOptions}
                  >
                    <Plus className="btn__icon" /> Add Another Option
                  </button>
                </div>
              )}
              <div className="text-center w-100 mt-4">
                <button
                  className="btn btn__purple save-purplebtn m-auto"
                  onClick={handleSaveQuestionAnswer}
                  isDisabled={questionError || optionError}
                >
                  {pollSaveLoader ? <Spin indicator={antIcon} /> : <>Save</>}
                </button>
              </div>
            </div>
          </div>
        )}
        {tab === "poll results" && (
          <div className="custom__container">
            <h3 className="answer-heading">{totalPollResultCount} Answered</h3>

            {/* <div className='right-answer'>
            <span className='right-answer-graph'></span>
            <p>2 - question</p>
           </div>
           <div className='wrong-answer'>
            <span className='wrong-answer-graph'></span>
            <p>1 - question</p>
           </div> */}
            <div className="mb-5">
              {totalPollResultCount &&
                totalPollResultCount > 0 &&
                pollResultData
                  .sort((a, b) => b.count - a.count)
                  .map((option) => (
                    <React.Fragment key={option.Answer}>
                      {/* <label className="option-label">{`${calculatePercentage(option.count)}% - ${option.Answer}`}</label> */}
                      <div
                        className={
                          option.Answer === option.CorrectAnswer
                            ? "right-answer"
                            : "wrong-answer"
                        }
                      >
                        <span
                          className={
                            option.Answer === option.CorrectAnswer
                              ? "right-answer-graph"
                              : "wrong-answer-graph"
                          }
                          style={{
                            width: `${calculatePercentage(option.count)}%`,
                          }}
                        ></span>
                        <p>
                          {option.count} ({calculatePercentage(option.count)}%)
                          - {option.Answer}
                        </p>
                      </div>
                    </React.Fragment>
                  ))}
            </div>
            <div className="table-responsive table__responsive--custom">
              <table className="table table__custom table-other-news">
                <thead>
                  <tr>
                    <th className=" ps-0">First Name</th>

                    <th style={{ paddingRight: "30px", paddingLeft: "30px" }}>
                      Last Name
                    </th>
                    <th style={{ paddingRight: "30px", paddingLeft: "30px" }}>
                      Email
                    </th>

                    <th style={{ paddingRight: "30px", paddingLeft: "30px" }}>
                      Answered Correctly
                    </th>

                    <th style={{ paddingRight: "30px", paddingLeft: "30px" }}>
                      Date
                    </th>
                    {/* <th style={{ paddingRight: '30px', paddingLeft: '30px' }}>
                      Link Clicked
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <Spin
                      size="large"
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 30, color: "#000" }}
                          spin
                        />
                      }
                      className="loader__full"
                    />
                  ) : (
                    pollData?.length > 0 &&
                    pollData?.map((an, i) => {
                      return (
                        <tr key={i}>
                          <td className="table-description  ps-0">
                            {an.FirstName ? an.FirstName : "-"}
                          </td>
                          <td
                            style={{
                              paddingRight: "30px",
                              paddingLeft: "30px",
                            }}
                          >
                            {an.LastName ? an.LastName : "-"}
                          </td>
                          <td
                            style={{
                              paddingRight: "30px",
                              paddingLeft: "30px",
                            }}
                          >
                            {an.Email ? an.Email : "-"}
                          </td>
                          {an.CorrectAnwer !== null &&
                          an.CorrectAnswer !== "" ? (
                            <td
                              style={{
                                paddingRight: "30px",
                                paddingLeft: "30px",
                              }}
                            >
                              {an.CorrectAnswer === an.UserAnswer
                                ? "Yes"
                                : "No"}
                            </td>
                          ) : (
                            <td
                              style={{
                                paddingRight: "30px",
                                paddingLeft: "30px",
                              }}
                            >
                              {"N/A"}
                            </td>
                          )}
                          <td
                            style={{
                              paddingRight: "30px",
                              paddingLeft: "30px",
                            }}
                            className="table__status"
                          >
                            {getConvertTimeToEast(an.Created)} (ET)
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
              {pollData?.length === 0 && !loader && isMounted && (
                <div className="table__blank">
                  <div className="table__blank--text">No Data</div>
                </div>
              )}
              {pollData?.length > 0 && (
                <div className="table__pagination">
                  <div className="table__pagination--results">
                    {pollData.length} results
                  </div>
                  <div className="table__pagination--nav">
                    <>
                      {pageSearchNumber - 1 >= 1 ? (
                        <button
                          type="button"
                          className="btn btn__default table__pagination--prev"
                          onClick={() => pageChange("previous")}
                        >
                          Previous
                        </button>
                      ) : (
                        <button
                          type="button"
                          disabled
                          className="btn btn__default table__pagination--prev disabled"
                        >
                          Previous
                        </button>
                      )}
                    </>
                    <>
                      {nextStep - pageSearchNumber > 0 ? (
                        <button
                          type="button"
                          className="btn btn__default table__pagination--next"
                          onClick={() => pageChange("next")}
                        >
                          Next
                        </button>
                      ) : (
                        <button
                          type="button"
                          disabled
                          className="btn btn__default table__pagination--next disabled"
                        >
                          Next
                        </button>
                      )}
                    </>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <ReactModal
          isOpen={modalIsOpen}
          // isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel=""
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered addcurated__list-modal"
        >
          <div className="modal-content">
            <div className="react-modal-header border-bottom">
              <h5 className="react-modal-title">Add Curated List Tag</h5>
              {/* <button className="btn react-modal-close" onClick={closeModal}>
                <Close />
              </button> */}
            </div>
            <div className="react-modal-body">
              <div className="form-group">
                <label className="label-primary">Add Tag</label>
                <div className="tag__group">
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    defaultValue={selectedCuratedValue}
                    onChange={(e) => handleChange(e)}
                    isMulti
                    options={curatedList}
                  />
                  {/* <button className="btn btn__purple">Video Streaming <Close className="close__icon btn__icon"/></button>
                      <button className="btn btn__purple">Other (Media & Entertainmnet)<Close className="close__icon btn__icon"/></button> */}
                </div>
                {/* <input type="text" name="" className="form-control" required /> */}
              </div>
            </div>
            <div className="react-modal-footer">
              <button
                onClick={() => closeModal()}
                type="button"
                className="btn btn__default"
              >
                Cancel
              </button>
              <button
                onClick={() => addCurated()}
                type="button"
                className="btn btn__purple"
              >
                {isLoading ? <Spin indicator={antIcon} /> : "Save"}
              </button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={deleteModal}
          onRequestClose={closeModal}
          ariaHideApp={false}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title ">Delete</h5>
            </div>
            <div className="react-modal-body">
              <p className="react-modal-body-text">
                Are you sure you want to delete this ? This cannot be undone.
              </p>
            </div>
            <div className="react-modal-footer">
              <button onClick={closeModal} className="btn btn__default">
                Cancel
              </button>
              <button
                onClick={() => removeTheme()}
                className="btn btn__purple ms-2"
              >
                {isLoading ? <Spin indicator={antIcon} /> : "Yes, Delete"}
              </button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={emailModal}
          onRequestClose={closeEmailModal}
          contentLabel=""
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered send__other-news-modal"
        >
          <div className="modal-content">
            <div className="react-modal-header border-bottom">
              <h5 className="react-modal-title">Send LT Weekly Email</h5>
            </div>
            <div className="react-modal-body">
              <div className="form-group">
                <label className="label-primary">Campaign Title</label>
                <input
                  type="text"
                  name="campaignTitle"
                  onChange={(e) => handleCampaignTitle(e.target.value)}
                  className="form-control"
                  value={campaignTitle}
                  required
                />
                {campaignTitleError && campaignTitleError !== "" && (
                  <>
                    <div className="invalid-feedback">{campaignTitleError}</div>
                  </>
                )}
              </div>
              <div className="send__email-input">
                <label className="label-primary">Mailing List</label>
                <select
                  className="form-select"
                  aria-label="Select Mailing List"
                  
                  onChange={(e) =>
                  {
                    if(e.target.value){
                      handleMailingList(mailingListData[e.target.value])
                      setMailingListError("")
                    }
                  }}
                >
                  <option value={-1}><span style={{color: "red"}}>Select Email List</span></option>
                  {mailingListData?.map((list, index) => {
                    if(list?.IsLive == isLive){
                      return <option value={index}>{list?.MailingDate}</option>
                    }
                  })}
                </select>
                <div
                  style={{
                    marginTop: "13px",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  <span>
                    
                    {userListId === "" || !userListId
                      ? ""
                      : parseInt(
                          selectedMailingList?.UserListIds
                        )?.toLocaleString("en-US")}{" "}
                    {userListId
                      ? parseInt(userListId) > 1
                        ? "Peoples"
                        : "People"
                      : ""}
                  </span>
                </div>
              </div>
              {mailingListError !== "" && (
                  <>
                    <div className="invalid-feedback">{mailingListError}</div>
                  </>
                )}
              <br />
              <div className="table-radio-container-list">
                {campaignTypeOptions.map((j) => {
                  return (
                    <div className="form--inline-radio">
                      <label className="custom__radio">
                        {" "}
                        {j.label}
                        <input
                          className="custom__radio--input"
                          type="radio"
                          checked={campaignType === j.value}
                          onChange={() => {
                            handleCampaignType(j.value);
                            // onChangeStatus(
                            //   j.value,
                            //   j.label,
                            //   an,
                            //   i
                            // );
                          }}
                        />
                        <span className="custom__radio--check"></span>
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="react-modal-footer">
              <button
                onClick={() => {
                  closeEmailModal();
                  setUserListId("");
                  setCampaignTitle("");
                  setCampaignType("Test")
                }}
                type="button"
                className="btn btn__default"
              >
                Cancel
              </button>
              <button
                onClick={() => sendLtWeeklyEmail()}
                type="button"
                className="btn btn__purple"
                disabled={IsSendButtonDisabled}
              >
                {isLoadingEmail ? <Spin indicator={antIcon} /> : "Send"}
              </button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={deleteNewsModal}
          onRequestClose={closeNewsModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title ">Delete Other News</h5>
            </div>
            <div className="react-modal-body">
              <p className="react-modal-body-text">
                Are you sure you want to delete this other news article? This
                action cannot be undone.
              </p>
            </div>
            <div className="react-modal-footer">
              <button onClick={closeNewsModal} className="btn btn__default">
                Cancel
              </button>
              <button
                onClick={() => removeOtherNews()}
                className="btn btn__orange ms-2"
              >
                {isLoading ? <Spin indicator={antIcon} /> : "Yes, Delete"}
              </button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={openWebsiteModal}
          onRequestClose={closeNewsModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title ">Show Website Toggle Alert</h5>
            </div>
            <div className="react-modal-body">
              <p className="react-modal-body-text">
                Are you sure you want to show IIM LT Weekly on the Website ?
              </p>
            </div>
            <div className="react-modal-footer">
              <button onClick={closeNewsModal} className="btn btn__default">
                Cancel
              </button>
              <button
                onClick={() => onIntroCheck(true, "ShowInWebsite")}
                className="btn btn__orange ms-2"
              >
                {isLoading ? <Spin indicator={antIcon} /> : "Yes"}
              </button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={openAppModal}
          onRequestClose={closeNewsModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title ">Show App Toggle Alert</h5>
            </div>
            <div className="react-modal-body">
              <p className="react-modal-body-text">
                Are you sure you want to IIM LT Weekly on the App ?
              </p>
            </div>
            <div className="react-modal-footer">
              <button onClick={closeNewsModal} className="btn btn__default">
                Cancel
              </button>
              <button
                onClick={() => onIntroCheck(true, "ShowInApp")}
                className="btn btn__orange ms-2"
              >
                {isLoading ? <Spin indicator={antIcon} /> : "Yes"}
              </button>
            </div>
          </div>
        </ReactModal>
      </main>
    </>
  );
}
